import React, { useState } from 'react';
import classes from "../../../../AddTalentForm.module.css";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Label, Row, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import HeaderOptionsButton from '../../../../../HeaderOptionsButton/HeaderOptionsButton';
import HeaderOptionsItem from '../../../../../HeaderOptionsButton/Components/HeaderOptionsItem/HeaderOptionsItem';
import { flagTalentAsInvalidLink } from '../../../../../../../utils/services/turboPlus2RestCalls';
import { showToastError, showToastSuccess } from '../../../../../../../utils/toastMessages';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import { removeAutoSourcedTalent } from "../../../../../../../utils/services/turboPlus2RestCalls";

const NumberLabel = (props) => {
    return <Label
                style={{fontSize: '20px',
                    display: 'inline-block',
                    background: props.isAutoSurfaced ?
                        '#147CA1' :
                        ''}}
            ># {props.index +1}</Label>
};

const TalentAddRow = props => {
    const {errors, touched} = props;
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuClicked = () => {
        setMenuIsOpen(!menuIsOpen);
    }

    const handleClickedOutside = () => {
        setMenuIsOpen(false);
    }

    const getTalentValidationState = (item,index) => {
        return touched.talent && touched.talent[index] && touched.talent[index][item] &&
        errors.talent && errors.talent[index] && errors.talent[index][item] ? 'error' : null
    }

    const getTalentErrorMessage = (item,index) => {
        return errors.talent && errors.talent[index] ?
            <HelpBlock>{errors.talent[index][item]}</HelpBlock> :
            null
    }

    // Function to handle "Profile no longer exists" action
    const handleProfileNoLongerExists = (index) => {
        
        const talentId = props.talent.id;

        flagTalentAsInvalidLink(talentId)
            .then(response => {
                props.handleRemoveItemFromForm(index);
                setMenuIsOpen(false);

                showToastSuccess("Talent profile link flagged as invalid");
            })
            .catch(error => {
                console.error('Failed to delete talent', error);
                showToastError("Failed to flag talent as invalid");
            });
    };

    //make an api call to remove talent
    const handleRemoveTalent = (talentId, roleId) => {
        removeAutoSourcedTalent(roleId, talentId)
            .then(() => {
                // Remove the talent from the form
                props.handleRemoveItemFromForm(props.index);
                showToastSuccess("Talent removed successfully");
            })
            .catch(error => {
                console.error('Failed to remove talent', error);
                showToastError("Failed to remove talent");
            });
    }
    return (
        <div className={classes.AddTalentRowItem}>
            <Row>
                <Col xs={1} >
                    <FormGroup>
                        <ControlLabel>&nbsp;</ControlLabel>
                        <FormControl
                            index={props.index}
                            isAutoSurfaced={props.talent.is_auto_surfaced}
                            componentClass={NumberLabel}
                        />
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup
                        controlId={`talent.${props.index}.link`}
                        validationState={getTalentValidationState('name', props.index)}
                    >
                        <ControlLabel>Talent Name</ControlLabel>
                        <FormControl
                            name={`talent.${props.index}.name`}
                            required
                            disabled={props.isSubmitting || props.talent.is_auto_surfaced}
                            value={props.talent.name}
                            onChange={props.handleChange}
                            type="text"
                            onBlur={props.handleBlur}
                            placeholder="Tom Bentz" />
                        <FormControl.Feedback />
                        {getTalentErrorMessage('name', props.index)}
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup
                        controlId={`talent.${props.index}.link`}
                        validationState={getTalentValidationState('link', props.index)}
                    >
                        <ControlLabel>
                            Link
                            {props.talent.is_auto_surfaced &&
                                <a href={props.talent.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon
                                        className={classes.ExternalLinkAltIcon}
                                        icon={faExternalLinkAlt}
                                    />
                                </a>
                            }
                        </ControlLabel>
                        <FormControl
                            name={`talent.${props.index}.link`}
                            required
                            disabled={props.isSubmitting || props.talent.is_auto_surfaced}
                            value={props.talent.link}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            type="text"
                            placeholder="A link for the customer to click" />
                        <FormControl.Feedback />
                        {getTalentErrorMessage('link', props.index)}
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup
                        controlId={`talent.${props.index}.gender`}
                    >
                        <ControlLabel>Gender:</ControlLabel>
                        <FormControl
                            value={props.talent.gender}
                            onChange={props.handleChange}
                            componentClass="select"
                            disabled={props.isSubmitting}
                            placeholder="select">
                            <option value="select">select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="unknown">Unknown</option>
                        </FormControl>
                        <FormControl.Feedback/>
                        <HelpBlock>Optional</HelpBlock>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup
                        controlId={`talent.${props.index}.isDiverse`}
                    >
                        <ControlLabel>DNI:</ControlLabel>
                        <FormControl
                            value={props.talent.isDiverse}
                            onChange={props.handleChange}
                            componentClass="select"
                            disabled={props.isSubmitting}
                            placeholder="select">
                            <option value="">select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </FormControl>
                        <FormControl.Feedback/>
                        <HelpBlock>Optional</HelpBlock>
                    </FormGroup>
                </Col>
                <Col xs={1}>
                    <FormGroup>                        
                        <ControlLabel>
                        {props.talent.is_auto_surfaced && props.talent.autosurface_pipeline_number ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-${props.index}`}>
                                            Pipeline {props.talent.autosurface_pipeline_number}
                                        </Tooltip>
                                    }
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        P{props.talent.autosurface_pipeline_number}
                                    </span>
                                </OverlayTrigger>

                                {props.talent.autosurface_pipeline_number === 6 && (
                                    <a
                                        onClick={() => handleRemoveTalent(props.talent.id, props.roleId)}
                                    >
                                        <FontAwesomeIcon
                                            className={classes.ExternalLinkAltIcon}
                                            icon={faBan}
                                        />
                                    </a>
                                )}
                            </div>
                        ) : (
                            <div style={{opacity: 0}}>.</div>
                        )}
                        </ControlLabel>
                        {props.talent.is_auto_surfaced ? (
                            <HeaderOptionsButton
                                menuClicked={handleMenuClicked} 
                                isOpen={menuIsOpen} 
                                clickedOutSide={handleClickedOutside}
                            >
                                <HeaderOptionsItem onClick={() => handleProfileNoLongerExists(props.index)}>
                                    Profile no longer exists
                                </HeaderOptionsItem>
                            </HeaderOptionsButton>
                        ) : (
                            <Button
                                disabled={props.isSubmitting}
                                style={{height: '35px'}}
                                onClick={() => props.handleRemoveItemFromForm(props.index)} bsStyle="danger" bsSize="xsmall" block>
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </Button>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default TalentAddRow;